import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import {
  IBalancoEstoque, IBalancoEstoqueItem, IBalancoEstoqueItemContagem, IBalancoEstoqueItemRevisao,
} from '@/models/Entidades/Estoques/IBalancoEstoque';
import { IDTOBalancoEstoqueItemResumo } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemResumo';
import { IDTOBalancoEstoqueItemContagem } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemContagem';
import { IDTOBalancoEstoqueItemRevisao } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemRevisao';
import { IDTOBalancoEstoqueRevisaoEstatisticas } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueRevisaoEstatisticas';

/**
 * Serviço Balanço de Estoques
 * Fornece todas regras de negócios e lógicas relacionado a Balanço de Estoques.
 */

class ServicoBalancoEstoque implements IServicoBase<IBalancoEstoque> {
    endPoint = 'balanco-estoque';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterListaTipos(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
      return result.data;
    }

    public async obterListaStatus(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/status`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IBalancoEstoque): string {
      return objeto.codigo.toString();
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async finalizar(codigo: number, zerarProdutosNaoContados?:boolean): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      if (zerarProdutosNaoContados !== undefined) {
        parametrosAdicionais += `?zerarProdutosNaoContados=${zerarProdutosNaoContados}`;
      }

      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/finalizar${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: IBalancoEstoque): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: IBalancoEstoque): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IBalancoEstoque> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async adicionarItens(codigoBalancoEstoque:number, itens: IBalancoEstoqueItem []): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigoBalancoEstoque}/itens`, itens);
      return result.data;
    }

    public async lancarContagem(codigoBalancoEstoque:number, codigoEmpresa:number, contagem: IBalancoEstoqueItemContagem, autoIncrementar?: boolean): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      if (autoIncrementar !== undefined) {
        parametrosAdicionais += `?autoIncrementar=${autoIncrementar}`;
      }

      const result: any = await this.apiERP.post(`${this.endPoint}/${codigoBalancoEstoque}/empresa/${codigoEmpresa}/itens/contagens${parametrosAdicionais}`, contagem);
      return result.data;
    }

    public async excluirContagem(codigo: number, empresa: number, codigoItemBalanco: number, codigoContagem: number, produto?:string): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      if (produto !== undefined) {
        parametrosAdicionais += `?produto=${produto}`;
      }
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}/itens/${codigoItemBalanco}/contagens/${codigoContagem}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterItensResumo(codigoBalanco: number, empresa: number): Promise<IDTOBalancoEstoqueItemResumo[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoBalanco}/empresa/${empresa}/itens/resumo`);
      return result.data;
    }

    public async obterContagens(codigoBalanco: number, empresa: number, codigoUsuarios?:number[], codigoDefinicoesProdutos?:number[], ultimosProdutosLancados?:boolean): Promise<IDTOBalancoEstoqueItemContagem[]> {
      let parametrosAdicionais = '';
      if (codigoUsuarios !== undefined) {
        codigoUsuarios.forEach((codigoUsuario) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigoUsuarios=${codigoUsuario}`;
        });
      }
      if (codigoDefinicoesProdutos !== undefined) {
        codigoDefinicoesProdutos.forEach((codigoDefinicao) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigoDefinicoesProdutos=${codigoDefinicao}`;
        });
      }

      if (ultimosProdutosLancados !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `ultimosProdutosLancados=${ultimosProdutosLancados}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoBalanco}/empresa/${empresa}/itens/contagens${parametrosAdicionais}`);
      return result.data;
    }

    public async obterRevisoes(codigo: number, empresa: number): Promise<IDTOBalancoEstoqueItemRevisao[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}/itens/revisoes`);
      return result.data;
    }

    public async lancarRevisao(codigoBalanco: number, codigoEmpresa:number, revisao: IBalancoEstoqueItemRevisao): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigoBalanco}/empresa/${codigoEmpresa}/itens/revisoes`, revisao);
      return result.data;
    }

    public async atualizarRevisao(codigoBalanco: number, codigoEmpresa:number, revisao: IBalancoEstoqueItemRevisao): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${codigoBalanco}/empresa/${codigoEmpresa}/itens/revisoes`, revisao);
      return result.data;
    }

    public async definirSaldoFinalRevisoes(codigoBalanco: number, codigoEmpresa:number, revisoes: IBalancoEstoqueItemRevisao[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigoBalanco}/empresa/${codigoEmpresa}/itens/revisoes/saldo-final`, revisoes);
      return result.data;
    }

    public async obterEstatisticasRevisao(codigo: number, empresa: number): Promise<IDTOBalancoEstoqueRevisaoEstatisticas> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}/itens/revisoes/estatisticas`);
      return result.data;
    }

    public async obterItemBalancoPorEstoqueItem(codigo: number, codigoEstoqueItem: number): Promise<IBalancoEstoqueItem> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/itens/estoque-item/${codigoEstoqueItem}`);
      return result.data;
    }

    public async cancelar(objeto: IBalancoEstoque): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}/empresa/${objeto.codigoEmpresa}/cancelar`);
      return result.data;
    }
}
export default ServicoBalancoEstoque;
